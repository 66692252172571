import React from "react";

const Exp = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">EXPERIENCE</div>
				<div id="abt">


				<h2>Devsecops Consultant at Statera Solutions</h2>
                <h3>[11 months] (August 2023 - July 2024)</h3>
                <p>
                    &gt; Set up devops environment with VCS, CI, SCA, SAST, as proof-of-concept to demonstrate
					Synopsys security tools integrated in a SDLC pipeline. Both on-prem and cloud hosted tools
                </p>

				<br />
               
                <h2>Intern at Singtel Trustwave Cybersecurity Institute</h2>
                <h3>[5 Months] (September 2020 - February 2021)</h3>
                <p>
                    &gt; Set up cyber-attack scenarios for corporate cybersecurity training, involving Windows 10,
					Active Directory, and Kali. Enabled clients to visually understand cyber-attack processes
                    <a href="https://dev.to/boiledsteak/simple-remote-code-execution-on-ejs-web-applications-with-express-fileupload-3325" className="italic"> [read more]</a><br/>
                    &gt; Developed python application that automated a previously manual process, achieving a
					300% increase in task completion speed and reducing turnaround time 
                    <a href="https://dev.to/boiledsteak/automatic-pdf-form-filler-web-app-58j9" className="italic"> [read more]</a> <br />
                </p>
                
              
                
            
			</div>
			</div>
			
</div>
			
		
		</div>
	);
};

export default Exp;
