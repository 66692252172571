import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import About from "./pages/About";
import Skills from "./pages/Skills";
import Exp from "./pages/Exp";
import Projects from "./pages/Projects";
import Certs from "./pages/Certs";
import ProjTentmaker from "./pages/ProjTentmaker";
import ThisWebsite from "./pages/ThisWebsite";

import Header from "./components/Header";
import Footer from "./components/Footer";


function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/exp" element={<Exp />} />
          <Route path="/certs" element={<Certs />} />
          <Route path="/projs" element={<Projects />} />
          <Route path="/projs/tentcard-maker" element={<ProjTentmaker />} />
          <Route path="/projs/this-website" element={<ThisWebsite />} />
          <Route path="*" element={<Navigate to="/about" replace />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
