import React from "react";
import { NavLink } from "react-router-dom";

const About = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">ABOUT</div>
				<div id="abt">
					<p>
					Hi, I’m Timothy, a cybersecurity student based in Singapore. My journey began with a Diploma in Infocomm Security Management from Singapore Polytechnic, where I gained expertise in cybersecurity, programming, databases, and networking. Amongst them all, one skill resonated with me the most — creating solutions.
					<br></br>
					<br></br>
					Learning about DevOps and cloud services, I discovered there's a whole market where I can solve problems using automation together with my cybersecurity knowledge.
					<br></br>
					<br></br>
					Having completed National Service, I am now pursuing a Bachelor’s Degree in Computer Science from Singapore Institute of Management, University of Wollongong. I look forward to finding my place in the growing field of cloud engineering and cybersecurity.
					</p>
				</div>
			</div>
			</div>
			

			
		
		</div>
	);
};

export default About;
