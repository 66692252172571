import React from "react";

const ThisWebsite = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			<div className="about-con">
                
				<div className="title">This Website</div>
                
                <div id="abt">
                    <a href="/projs">👈 back to projects</a>
                    <div className="title2">This Website</div>
                    
                    <img
                        className="proj-pic"
                        src={process.env.PUBLIC_URL + "/images/yaml.jpg"}
                        alt=""
                    />
					<br></br>
                    <p>
						My portfolio website you are on!
						<br></br>
						<br></br>
						I created this website primarily to find a job. I believe this is a convenient medium for employers to see what I offer, and a practical way for me to showcase my abilities. I am considering integrating Kubernetes...
						<br></br>
						<br></br>
						<h2>Technical Stack:</h2>
						<ol className="goodList">
							<ul>
								<li><strong>Frontend:</strong> React</li>
								<li><strong>Backend:</strong> AWS Lambda (serverless)</li>
								<li><strong>Web Hosting:</strong> AWS CloudFront (CDN)</li>
								<li><strong>DNS & Firewall:</strong> Cloudflare</li>
								<li><strong>CI/CD:</strong> GitLab</li>
								<li><strong>Infrastructure as Code (IaC):</strong> HCP Terraform Cloud</li>
								<li><strong>Security Scans:</strong> Snyk</li>
							</ul>
						</ol>
						
						<br></br>
						This project not only highlights my experience with modern tools but also demonstrates my understanding of real-world system design. Importantly, it has minimal operational costs, utilising free-tier services like AWS Lambda and Cloudflare to maintain affordability. The only cost is the domain, which is under $15 SGD annually.
						<br></br>
						<br></br>
						<h2>Security:</h2>
						And of course as a cybersecurity student, I did not skimp on security either. The $15 / year for my domain also includes Cloudflare's security features integrated. Their free tier includes many robust features such as mTLS, quic, HTTPs only, DDoS protection and AI bot protection - these are to name of but a few.
						<br></br>
						AWS Cloudfront has security features as well such as XSS protection, strict MIME type enforcement, HTTP Strict Transport Security, iframe clickjacking protection, and all of AWS's industry leading granular IAM security. I could enable an extra layer of WAF as well but I feel for my use case, it is an uneeded cost.
						<br></br>
						<br></br>
						So that covers the transport and internet layer. How about the application itself? Yes the bread and butter of secure web dev such as input validation, hiding error pages, API keys, etc are done. Now for organisations' favourite - compliance! There are many AppSec scanning tools in the market. They offer software bill of materials (SBOM), reports, compliance checks etc. I am using the Snyk free tier which only includes static application security testing (SAST) and software composition analysis (SCA). Snyk's paid subscriptions offer more comprehensive features described earlier but once again, for my simple portfolio website, I feel this is sufficient as a proof-of-concept. Although additional open source tools could be integrated into the CI/CD pipeline, I’ve kept the approach lean for cost-effectiveness and simplicity.
						<br></br>
						<br></br>
						<h2>The Big Picture:</h2>
						The overarching message I am trying to convey is that I have created a simple portfolio website with industry standard tools that can be scaled much larger due to the nature of cloud services. And my development process always keeps in mind cost! Whether it's a private business or a public organisation, IT is always a non revenue generating cut of the budget pie. Cybersecurity even more so. This is why I am interested in cloud services. I believe cloud based solutions give organisations an affordable entry point to having reliable infrastructure without sacrificing security. It's true that over time cloud services can rake up costs that's why a cloud engineer is not only required to know how to build infrastructure using cloud tools, but also evaluate and consult on how to manage costs. I aim to pursue a career in this field.
					</p>
            

                </div>
			</div>
			
            </div>
			
		
		</div>
	);
};

export default ThisWebsite;
