import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';



const Header = () => {

	// to scroll into correct segment of webpage
	const location = useLocation();
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [location.pathname]);

	// for swipeable drawer
	const [open, setOpen] = React.useState(false);
	// Modified to toggle drawer open/close without needing 'newOpen'
    const toggleDrawer = () => {
        setOpen(!open); // Toggle between true and false
    };

	// maps the drawer menu labels to route
	const menuItems = [
		{ label: 'ABOUT', path: '/about' },
		{ label: 'WHAT I OFFER', path: '/skills' },
		{ label: 'EXPERIENCE', path: '/exp' },
		{ label: 'CERTIFICATION', path: '/certs' },
		{ label: 'PROJECTS', path: '/projs' }
	];

	

	return (
		<div className="page">
			<header>
				<div className="headerCon">
					<div className="logo-con">
						<div className="thelogo">
							<NavLink to="/">
								<img
									id="logoPic"
									src={process.env.PUBLIC_URL + "/images/myface1.jpg"}
									alt=""
								/>
							</NavLink>
						</div>
						<div className="spacing1"></div>
						<div className="myname">
							<p>
								Timothy Mah
							</p>
						</div>
					</div>

					<div className="contact-deets">
						<div className="contact-deets-row">
							<a href="https://t.me/cplxamah">
								<p>
									Telegram me!
								</p>
								<img
									id="telelogotop"
									src={process.env.PUBLIC_URL + "/images/telegram-logo.svg"}
									alt=""
								/>
							</a>
							
						</div>
					</div>
					
				</div>
				<div className="sidebar">
					<ul>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/about">ABOUT</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/skills">WHAT I OFFER</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/exp">EXPERIENCE</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/certs">CERTIFICATION</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/projs">PROJECTS</NavLink></li>
						
					</ul>
				</div>
			
			</header>
	


			{/*Hamburger Menu 2*/}
			<div>
			{/* Button to toggle drawer, modified onClick handler to simply toggle open/close */}
            <Button 
                className={`button ${open ? "open" : ""}`}
                onClick={toggleDrawer} 
                tabindex="0"  
                sx={{ display: { md: 'block', position: 'fixed', lg: 'none' } }}>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </Button>
			{/* SwipeableDrawer uses the simplified toggleDrawer method */}
            <SwipeableDrawer
                anchor="left"
                open={open}
                onClose={toggleDrawer} // Close drawer when user interacts outside
                onOpen={toggleDrawer} // This line is now optional, kept for clarity
				PaperProps={{sx: { backgroundColor: '#efeed8'}}}
            >
				<div id="nav-content" tabindex="0">
					<div className="mobilemenu-photo-con">
						<img
							id="logoPic"
							src={process.env.PUBLIC_URL + "/images/myface1.jpg"}
							alt=""
						/>
						<a id="telegram-link" href="https://t.me/cplxamah">
							<p id="mobile-tele-me">
								Telegram me!
							</p>
							<img
								id="telelogo"
								src={process.env.PUBLIC_URL + "/images/telegram-logo.svg"}
								alt=""
							/>
						</a>
						
					</div>
				</div>
					
				

                <Box
                    sx={{ width: 300, backgroundColor: '#efeed8' }} 
                    role="presentation"
                    onClick={toggleDrawer} // Close drawer on clicking an item
                    onKeyDown={toggleDrawer} // Close drawer on key down inside drawer
                >
					
                    <List>
						{menuItems.map((item, index) => (
        						<ListItem key={item.label} disablePadding>
                                <NavLink
                                    to={item.path}
                                    style={({ isActive }) => ({
                                        textDecoration: 'none',
                                        color: isActive ? 'brown' : 'black',
                                        width: '100%',
                                        display: 'block',
                                    })}
                                >
                                    <ListItemButton>
									<ListItemText 
										primary={item.label}
										slotProps={{
											primary: {
												style: {
													fontWeight: '500', // Set the font weight for the text
												}
											}
										}}
									/>
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>
			</div>
			
		
		</div>
	);
};

export default Header;
