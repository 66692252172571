import React, { useState } from "react";
import ApiCallComponent from "../helpers/api-call.jsx"; // Import the ApiCallComponent

const ProjTentmaker = () => {
  const [apiResponse, setApiResponse] = useState(null);

  const handleApiResponse = (response) => {
    setApiResponse(response); // Update the state with the API response
  };

  return (
    <div id="b1" style={{ backgroundColor: "#efeed8" }}>
      <div className="header-spacing"></div>
      <div className="aboutpage-con">
        <div className="about-con">
          <div className="title">Tent card maker</div>
          <div id="abt">
            <a href="/projs">👈 back to projects</a>
            <div className="title2">Tent Card Maker</div>
            <img
              className="proj-pic"
              src={process.env.PUBLIC_URL + "/images/tent-card.jpg"}
              alt=""
            />
            <br />
            <p>
              I have already posted a write-up about my tent card maker app{" "}
              <a
                className="italic"
                href="https://dev.to/boiledsteak/automatic-pdf-form-filler-web-app-58j9"
              >
                [here]
              </a>
              <br />
              <br />
              But what's different now, is that I have migrated it into AWS! It
              is live here on my website!
            </p>

            {/* Call the ApiCallComponent */}
            <ApiCallComponent onApiResponse={handleApiResponse} />

            {/* Display API response */}
            {apiResponse && (
              <p>{apiResponse ? JSON.stringify(apiResponse, null, 2) : "No response yet."}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjTentmaker;
