import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const Skills = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>		
			<div className="about-con">
				<div className="title">WHAT I OFFER</div>
				<div id="abt">
					<p>
					Skills and knowledge in,
					</p>
		
					<div className="bubble-con">
						<div className="bubble-row">
							<div className="bubble">Programming</div><div className="bubble">Full stack web dev</div><div className="bubble">Cybersecurity</div><div className="bubble">Networking</div><div className="bubble">DevSecOps</div><div className="bubble">Cloud infrastructure</div>
						</div>
						<hr className="dotted-line" style={{ borderTop: '3px dotted #000', margin: '20px 0' }}></hr>
					</div>
					<div>
						<p>
							- C, C++, Java, Python, HTML, CSS, NodeJS, ReactJS, MERN, Flask, JSP, PHP, XAMPP<br />
							- Mongo, MySQL, Oracle DB, Dynamo DB<br />
							- Synopsys Blackduck SCA Synopsys Coverity SAST Synopsys Codesight Synopsys Software Risk Manager Synopsys Polaris SaaS<br />
							- Jenkins, Github Actions, Gitlab CI, Snyk, HCP Terraform, Digital Ocean, AWS<br />
							- Linux, Kali, Ubuntu, RHEL, Windows, Active Directory<br />
							- VMWare ESXi, vSphere, Workstation, Docker<br />
							- Telegram bots<br />
							- Adobe Photoshop, Illustrator, Premiere pro, After Effects
						</p>
						</div>
				</div>
			</div>
			</div>
	);
};

export default Skills;
