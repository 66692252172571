import React from "react";

const Projects = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">PROJECTS</div>
                    <div id="abt">
                       
						<div className="proj-con">

						<div className="proj-unit">
								<a href="./projs/this-website">
									<img
										className="proj-pic"
										src={process.env.PUBLIC_URL + "/images/yaml.jpg"}
										alt=""
									/>
								</a>
								
								<a href="./projs/this-website">
									<h2>
										This Website
									</h2>
									<p>
										CICD, DevOps, Cloud Services, IAC
									</p>
									
								</a>
							</div>


							<div className="proj-unit">
								<a href="./projs/tentcard-maker">
									<img
										className="proj-pic"
										src={process.env.PUBLIC_URL + "/images/tent-card.jpg"}
										alt=""
									/>
								</a>
								
								<a href="./projs/tentcard-maker">
									<h2>
										Tent Card Maker
									</h2>
									<p>
										Automation, Cloud Services
									</p>
									
								</a>
							</div>

							
							
						</div>
                

                    </div>
			</div>
			
</div>
			
		
		</div>
	);
};

export default Projects;
