import React, { useState } from "react";

const ApiCallComponent = ({ onApiResponse }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://api.timothy-mah.com/lambda", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ /* Include any necessary data here */ }),
      });

      if (response.ok) {
        const data = await response.json();
        onApiResponse(data); // Send response back to parent
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error("Error:", error);
      onApiResponse({ error: "Failed to fetch data" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick} disabled={isLoading}>
        {isLoading ? "Loading..." : "Call API"}
      </button>
    </div>
  );
};

export default ApiCallComponent;
